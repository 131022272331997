import { FC, Fragment, useEffect, useState } from 'react';
import { SearchFlight } from '@bepro-travel/fe.flight';
import { SearchTours, SearchPackages } from '@bepro-travel/fe.package';
import { SearchHotel } from '@bepro-travel/fe.hotel';
import { SearchTransfer } from '@bepro-travel/fe.transfer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBed,
  faCar,
  faPlane,
  faPlaneLock,
  faSuitcase,
  faTicket,
  faTrain,
} from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { SearchDynamic, SearchDynamicFH } from '@bepro-travel/fe.dynamic';
import { SearchBoxTabs } from '../constants/generic';

interface MultiSearchBoxProps {
  config?: string;
}

const MultiSearchBox: FC<MultiSearchBoxProps> = (props) => {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<SearchBoxTabs>();

  const defaultConfig = 'FLT_PAC_TOR_HTL_TRA_FHS_FLH';
  const config = props.config?.toUpperCase() || defaultConfig;
  const tabs = config.split('_') as SearchBoxTabs[];

  useEffect(() => {
    setActiveTab(tabs[1]);
  }, []); // eslint-disable-line

  const getOrder = (tab: SearchBoxTabs) => {
    return tabs.indexOf(tab);
  };

  const isVisible = (tab: SearchBoxTabs) => {
    return tabs.includes(tab);
  };

  return (
    <Fragment>
      <div className="tabs">
        {isVisible(SearchBoxTabs.flight) && (
          <div
            className={'tab' + (activeTab === SearchBoxTabs.flight ? ' active' : '')}
            onClick={() => setActiveTab(SearchBoxTabs.flight)}
            style={{ order: getOrder(SearchBoxTabs.flight) }}
          >
            <FontAwesomeIcon icon={faPlane} />
            {t('flight.s')}
          </div>
        )}

        {isVisible(SearchBoxTabs.package) && (
          <div
            className={'tab' + (activeTab === SearchBoxTabs.package ? ' active' : '')}
            onClick={() => setActiveTab(SearchBoxTabs.package)}
            style={{ order: getOrder(SearchBoxTabs.package) }}
          >
            <FontAwesomeIcon icon={faSuitcase} />
            {t('package.s')}
          </div>
        )}
        {isVisible(SearchBoxTabs.tours) && (
          <div
            className={'tab' + (activeTab === SearchBoxTabs.tours ? ' active' : '')}
            onClick={() => setActiveTab(SearchBoxTabs.tours)}
            style={{ order: getOrder(SearchBoxTabs.tours) }}
          >
            <FontAwesomeIcon icon={faTrain} />
            {t('package.tours')}
          </div>
        )}
        {isVisible(SearchBoxTabs.hotel) && (
          <div
            className={'tab' + (activeTab === SearchBoxTabs.hotel ? ' active' : '')}
            onClick={() => setActiveTab(SearchBoxTabs.hotel)}
            style={{ order: getOrder(SearchBoxTabs.hotel) }}
          >
            <FontAwesomeIcon icon={faBed} />
            {t('hotel.s')}
          </div>
        )}
        {isVisible(SearchBoxTabs.transfer) && (
          <div
            className={'tab' + (activeTab === SearchBoxTabs.transfer ? ' active' : '')}
            onClick={() => setActiveTab(SearchBoxTabs.transfer)}
            style={{ order: getOrder(SearchBoxTabs.transfer) }}
          >
            <FontAwesomeIcon icon={faCar} />
            {t('transfer.s')}
          </div>
        )}
        {isVisible(SearchBoxTabs.dynamicFH) && (
          <div
            className={'tab' + (activeTab === SearchBoxTabs.dynamicFH ? ' active' : '')}
            onClick={() => setActiveTab(SearchBoxTabs.dynamicFH)}
            style={{ order: getOrder(SearchBoxTabs.dynamicFH) }}
          >
            <FontAwesomeIcon icon={faPlaneLock} />
            {t('dyn.fl_htl')}
          </div>
        )}
        {isVisible(SearchBoxTabs.dynamicFHS) && (
          <div
            className={'tab' + (activeTab === SearchBoxTabs.dynamicFHS ? ' active' : '')}
            onClick={() => setActiveTab(SearchBoxTabs.dynamicFHS)}
            style={{ order: getOrder(SearchBoxTabs.dynamicFHS) }}
          >
            <FontAwesomeIcon icon={faTicket} />
            {t('dyn.dynamic')}
          </div>
        )}
      </div>
      {activeTab === SearchBoxTabs.flight && isVisible(SearchBoxTabs.flight) && (
        <SearchFlight loadingSearch={false} onClickStop={() => {}} />
      )}
      {activeTab === SearchBoxTabs.package && isVisible(SearchBoxTabs.package) && (
        <SearchPackages />
      )}
      {activeTab === SearchBoxTabs.tours && isVisible(SearchBoxTabs.tours) && <SearchTours />}
      {activeTab === SearchBoxTabs.hotel && isVisible(SearchBoxTabs.hotel) && (
        <SearchHotel loadingSearch={false} onClickStop={() => {}} />
      )}
      {activeTab === SearchBoxTabs.transfer && isVisible(SearchBoxTabs.transfer) && (
        <SearchTransfer />
      )}
      {activeTab === SearchBoxTabs.dynamicFH && isVisible(SearchBoxTabs.dynamicFH) && (
        <SearchDynamicFH matrixConfig="Dynamic_FMHM" />
      )}
      {activeTab === SearchBoxTabs.dynamicFHS && isVisible(SearchBoxTabs.dynamicFHS) && (
        <SearchDynamic matrixConfig="Dynamic_FMHMSM" />
      )}
    </Fragment>
  );
};

export default MultiSearchBox;
