import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const PaymentProcessPage = () => {
  const location = useLocation();
  useEffect(() => {
    (async () => {
      try {
        const path = 'http://localhost:7042' + location.pathname + location.search;
        fetch(path);
      } catch (err: any) {}
    })();
  }, []); //eslint-disable-line
  return <div></div>;
};

export default PaymentProcessPage;
